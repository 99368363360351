// extracted by mini-css-extract-plugin
export var container = "FooterTemplate-module--container--00873";
export var contentGrid = "FooterTemplate-module--contentGrid--e389e";
export var dynamicSectionContent = "FooterTemplate-module--dynamicSectionContent--8b06d";
export var dynamicSectionTitle = "FooterTemplate-module--dynamicSectionTitle--4031c";
export var footer = "FooterTemplate-module--footer--6414a";
export var iconLeft = "FooterTemplate-module--iconLeft--8fe4b";
export var iconRight = "FooterTemplate-module--iconRight--39bb7";
export var image = "FooterTemplate-module--image--c97fd";
export var imageContainer = "FooterTemplate-module--imageContainer--85623";
export var link = "FooterTemplate-module--link--0ded0";
export var logoAndConduction = "FooterTemplate-module--logoAndConduction--27a1b";
export var withLoveConductionLink = "FooterTemplate-module--withLoveConductionLink--272fd";
export var withLoveLink = "FooterTemplate-module--withLoveLink--c6f55";